<script>
import { mapState, mapGetters } from 'vuex';
import { Drag, DropList } from 'vue-easy-dnd';

export default {
  name: 'Directory',
  components: { Drag, DropList },
  props: [],
  data() {
    return {};
  },
  computed: {
    ...mapState({
      selectedProject: state => state.selectedProject[0],
    }),
    ...mapGetters(['scopeFields']),
    directoryValues() {
      return (this.selectedProject && this.selectedProject.fieldValues) || {};
    },
    usedValues() {
      return (
        (this.selectedProject && this.selectedProject.usedFieldValues) || {}
      );
    },
  },
  mounted() {},
  created() {},
  methods: {
    dropper(e) {
      console.log(e);
    },
    fields(scope) {
      const fields = this.scopeFields[scope];
      return fields && [...fields.values()].sort();
    },
    remove(array, field, value) {
      const index = array[field].indexOf(value);
      if (array[field]) array[field].splice(index, 1);
    },
    insertUsedValue({ data, index }) {
      const { field, item } = data;
      (this.used[field] || []).splice(index, 0, item);
    },
    insertScopeValue({ data, index }) {
      const { field, item } = data;
      (this.scope[field] || []).splice(index, 0, item);
    },
  },
};
</script>

<template>
  <v-container fluid>
    <v-row dense class="scopeFields">
      <v-col
        v-for="(field, n) in fields('scopeFields')"
        :key="n"
        sm="12"
        md="6"
        lg="4"
        xl="3"
        class="d-flex flex-column"
      >
        <v-card tile class="directory d-flex flex-column align-self-stretch">
          <v-card-title class="">
            {{ field | snakeCaseToTitleCase }}
          </v-card-title>
          <v-card-text class="py-0" style="flex-grow: 1">
            <v-container fluid>
              <v-row class="text-no-wrap">
                <v-col sm="6" class="pa-0 d-flex flex-column">
                  <div class="text-caption default--text font-weight-medium">
                    Scope
                  </div>
                  <v-list class="d-flex pr-4 flex-column" dense>
                    <drop-list
                      :items="directoryValues[field] || []"
                      :accepts-data="d => d.field === field && d.list == 1"
                      class="align-self-stretch pb-5"
                      mode="cut"
                      @insert="insertScopeValue"
                    >
                      <template #item="{ item }">
                        <drag
                          :key="item"
                          :data="{ item, field, list: 2 }"
                          class="item"
                          @cut="remove(scope, field, item)"
                        >
                          <v-list-item class="pa-1 pb-2">
                            <v-chip label small class="grfn darken-2">
                              {{ item }}
                            </v-chip>
                          </v-list-item>
                        </drag>
                      </template>
                      <template #feedback="">
                        <div class="item feedback">
                          {{ data }}
                        </div>
                      </template>
                    </drop-list>
                    <v-list-item class="pa-1 pb-2 pt-0 mt-0">
                      <v-btn disabled small color="secondary darken-2" icon>
                        <v-icon small> mdi-plus-circle </v-icon>
                      </v-btn>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col sm="6" class="pa-0 d-flex flex-column">
                  <div class="text-caption default--text font-weight-medium">
                    Previously Used
                  </div>
                  <v-list class="d-flex pr-1" dense>
                    <drop-list
                      :items="usedValues[field] || []"
                      :accepts-data="d => d.field === field && d.list == 2"
                      class="align-self-stretch pb-10"
                      mode="cut"
                      @insert="insertUsedValue"
                    >
                      <template #item="{ item }">
                        <drag
                          :key="item"
                          :data="{ item, field, list: 1 }"
                          class="item"
                          @cut="remove(used, field, item)"
                        >
                          <v-list-item class="pa-1 pb-0">
                            <v-chip
                              label
                              small
                              class="primary lighten-1 default--text"
                            >
                              {{ item }}
                            </v-chip>
                          </v-list-item>
                        </drag>
                      </template>
                      <template #feedback="">
                        <div class="item feedback">
                          {{ data }}
                        </div>
                      </template>
                    </drop-list>
                  </v-list>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-divider class="my-4" />
    <v-row dense class="usedFields" align="stretch">
      <v-col
        v-for="(field, n) in fields('pastValuesOnly')"
        :key="n"
        sm="12"
        md="6"
        lg="4"
        xl="3"
      >
        <v-card tile>
          <v-card-title>{{ field | snakeCaseToTitleCase }}</v-card-title>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
.col .directory,
.col .v-list {
  /* width: 100%; */
  /* min-height: 50%; */
  flex-grow: 1;
}
.col .drop-list {
  width: 100%;
}

.v-list-item {
  min-height: 0;
}

.drop-list .v-chip {
  width: 100%;
}

.drop-list.inserting.drop-in.drop-allowed {
  background: var(--v-grfn-base);
}

.v-list.d-flex {
  margin-left: -6px;
  margin-right: -6px;
}
</style>
